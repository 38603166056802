// project imports
import config from 'config';

// action - state management
import * as actionTypes from './actions';

const userInfor = JSON.parse(window.localStorage.getItem('userInfo') || '{}');
const id = localStorage.getItem('MENU_ID') || 'lottery';
const sportPath = ['/game_sports', '/game_esports'];
export const initialState = {
  isOpen: [id], // for active default menu
  defaultId: 'default',
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  opened: true,
  token: window.localStorage.getItem('token') || '',
  userInfor: userInfor,
  address: window.localStorage.getItem('address') || '',
  usdtAccount: window.localStorage.getItem('usdtAccount') || 0.0,
  openLoginModel: false,
  openNetworkModel: false,
  roundInfor: [],
  babPreiceUsdt: 0,
  openLoginAndResgin: false,
  type: 'login',
  sumitNicknameModel: false,
  resetPasswordModel: false,
  profileId: window.localStorage.getItem('PROFILE_ID') || '1',
  totalBet: window.localStorage.getItem('totalBet') || 0,
  isCashier: false,
  isBuyCrypto: false,
  isCoupons: false,
  inGameBallsCount: [],
  plinkoRuning: false,
  plinkHash: '',

  mobileMenuOpen: false,
  sidebarMenu: window.localStorage.getItem('currentMenu') || '',
  menuItem: window.localStorage.getItem('currentmenuItem') || '',
  isShowChat: false,
  betRecordList: [],
  betHistory: [],
  betAllRecordList: [],
  noticeOpen: false,
  noticeContent: '',
  tradeList: [],
  setUpdateClaim: false,
  isGlobalLoading: false,
  isLogin: 0,
  fps: 0,
  sportView: false,
  providerList: [],
  verify_2fa: false,
  ipVerifiy: false
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
  let id;
  switch (action.type) {
    case actionTypes.MENU_OPEN:
      id = action.id;
      return {
        ...state,
        isOpen: [id]
      };
    case actionTypes.SET_MENU:
      return {
        ...state,
        opened: action.opened
      };
    case actionTypes.SET_FONT_FAMILY:
      return {
        ...state,
        fontFamily: action.fontFamily
      };
    case actionTypes.SET_BORDER_RADIUS:
      return {
        ...state,
        borderRadius: action.borderRadius
      };
    case actionTypes.SET_TOKEN:
      return {
        ...state,
        token: action.token
      };
    case actionTypes.SET_USER_INFOR:
      return {
        ...state,
        userInfor: action.userInfor
      };
    case actionTypes.SET_ADDRESS:
      return {
        ...state,
        address: action.address
      };
    case actionTypes.SET_LOGIN_MODEL:
      return {
        ...state,
        openLoginModel: action.openLoginModel
      };
    case actionTypes.SET_NETWORK_MODEL:
      return {
        ...state,
        openNetworkModel: action.openNetworkModel
      };
    case actionTypes.ROUND_INFOR:
      return {
        ...state,
        roundInfor: action.roundInfor
      };
    case actionTypes.SET_BNB_PRICE:
      return {
        ...state,
        babPreiceUsdt: action.babPreiceUsdt
      };
    case actionTypes.SET_LOGIN_RESIGN_OPEN:
      return {
        ...state,
        openLoginAndResgin: action.openLoginAndResgin
      };
    case actionTypes.LOGIN_OR_RESI:
      return {
        ...state,
        type: action.typeLogin
      };
    case actionTypes.SET_RESETPASSWORD_OPEN:
      return {
        ...state,
        resetPasswordModel: action.resetPasswordModel
      };
    case actionTypes.SET_SUBMIT_NICKNAME_OPEN:
      return {
        ...state,
        sumitNicknameModel: action.sumitNicknameModel
      };
    case actionTypes.SET_USDT_ACCOUNT:
      if (action.firstBet && action.usdtAccount !== state.usdtAccount) {
        !window.localStorage.getItem('totalBetNum') &&
          window.localStorage.getItem('invit_code') === 'fbpro' &&
          fbq('trackCustom', 'FirstBet');
        window.localStorage.setItem('totalBetNum', 1);
      }
      return {
        ...state,
        usdtAccount: action.usdtAccount
      };
    case actionTypes.PROFILE_ID:
      return {
        ...state,
        profileId: action.profileId
      };
    case actionTypes.TOTAL_BET_AMOUNT:
      return {
        ...state,
        totalBet: action.totalBet
      };
    case actionTypes.IS_CASHIER:
      return {
        ...state,
        isCashier: action.isCashier
      };
    case actionTypes.IS_BUY_CRYPTO:
      return {
        ...state,
        isBuyCrypto: action.isBuyCrypto
      };
    case actionTypes.IS_COUPONS:
      return {
        ...state,
        isCoupons: action.isCoupons
      };
    case actionTypes.INCRE_PLINKO_VALL_NUMBER:
      return {
        ...state,
        inGameBallsCount: action.ballList
      };
    case actionTypes.DECRE_PLINKO_VALL_NUMBER:
      const list = state.inGameBallsCount.filter((item) => item.id != action.id);
      return {
        ...state,
        inGameBallsCount: list
      };
    case actionTypes.PLINKO_RUNING:
      return {
        ...state,
        plinkoRuning: action.plinkoRuning
      };
    case actionTypes.PLINKO_HASH:
      return {
        ...state,
        plinkoHash: action.plinkoHash
      };
    case actionTypes.SIDEBAR_MENU:
      return {
        ...state,
        sidebarMenu: action.sidebarMenu
      };
    case actionTypes.MENU_ITEM:
      return {
        ...state,
        menuItem: action.menuItem
      };
    case actionTypes.MOBILE_MENU_OPEN:
      return {
        ...state,
        mobileMenuOpen: action.mobileMenuOpen
      };
    case actionTypes.SHOW_CHAT:
      return {
        ...state,
        isShowChat: action.isShowChat
      };
    case actionTypes.BET_RECORD_LIST:
      const winList = action.betRecordList.filter((item) => item.bet.pay_out * 1 > 0);
      return {
        ...state,
        betRecordList: [...winList, ...state.betRecordList]
      };
    case actionTypes.BET_RECORD_HISTORY_LIST:
      return {
        ...state,
        betHistory: action.betHistory
      };

    case actionTypes.BET_ALL_RECORD_LIST:
      return {
        ...state,
        betAllRecordList: action.betAllRecordList
      };
    case actionTypes.SHOW_NOTICE:
      return {
        ...state,
        noticeOpen: action.noticeOpen
      };
    case actionTypes.SHOW_NOTICE_CONTENT:
      return {
        ...state,
        noticeContent: action.noticeContent
      };
    case actionTypes.SET_TRADE_LIST:
      const obj = action.tradeList[0];
      const { BaseVolume, HighPrice, LastPrice, LowPrice, Percent, QuoteVolume } = obj;
      const { tradeList } = state;
      const saveList = [...state.tradeList];
      let newList = [];
      if (action.tradeList.length == 1) {
        newList = tradeList.map((item, index) => {
          if (item.Symbol === obj.Symbol) {
            item.isDis = LastPrice * 1 > saveList[index].LastPrice * 1;
            item.BaseVolume = BaseVolume;
            item.HighPrice = HighPrice;
            item.LastPrice = LastPrice;
            item.LowPrice = LowPrice;
            item.Percent = Percent;
            item.QuoteVolume = QuoteVolume;
          }
          return item;
        });
      }
      return {
        ...state,
        tradeList: action.tradeList.length == 1 ? newList : action.tradeList
      };
    case actionTypes.SET_UPDATE_CLAIM: {
      return {
        ...state,
        setUpdateClaim: action.setUpdateClaim
      };
    }
    case actionTypes.SET_GLOBAL_LOADING: {
      return {
        ...state,
        isGlobalLoading: action.isGlobalLoading
      };
    }
    case actionTypes.SET_WEBSCOKET_LOGIN: {
      return {
        ...state,
        isLogin: action.isLogin
      };
    }
    case actionTypes.SET_FPS: {
      return {
        ...state,
        fps: action.fps
      };
    }
    case actionTypes.SET_SPORT_VIEW: {
      const flag = sportPath.includes(action.pathName);
      return {
        ...state,
        sportView: flag
      };
    }
    case actionTypes.SET_PROVIDES_LIST: {
      return {
        ...state,
        providerList: action.providerList
      };
    }
    case actionTypes.SET_2FA_VERIFY: {
      return {
        ...state,
        verify_2fa: action.verify_2fa
      };
    }
    case actionTypes.SET_IP_VERIFIY: {
      return {
        ...state,
        ipVerifiy: action.ipVerifiy
      };
    }
    default:
      return state;
  }
};

export default customizationReducer;
