import { fetchPost, fetchGet } from '../useRequest';

export function setUserInfor(data) {
  return fetchPost('/bits-api/game-user/userCentre/modifyData', data);
}
export function setAnonymity(data) {
  return fetchPost('/bits-api/game-user/userCentre/setAnonymity', data);
}

export function editPassword(data) {
  return fetchPost('/bits-api/game-user/userCentre/modifyPassword', data);
}
export function sendRemoteLoginCode(data) {
  return fetchPost('/bits-api/game-user/userCentre/sendRemoteLoginCode', data);
}
export function restPassword(data) {
  return fetchPost('/bits-api/game-user/userCentre/sendAuthCode', data);
}
export function addrBindEmail(data) {
  return fetchPost('/bits-api/game-user/app-api/v2/auth/addrBindEmail', data);
}
export function checkCode(data) {
  return fetchPost('/bits-api/game-user/userCentre/checkCode', data);
}
export function changePassword(data) {
  return fetchPost('/bits-api/game-user/userCentre/forgotPassword', data);
}
export function getUserAsset() {
  return fetchGet('/bits-api/game-wallet/asset/getUserAssets');
}
export function claimedReward() {
  return fetchPost('/bits-api/game-user/invitation/claimed');
}
export function getRewardInfor() {
  return fetchGet('/bits-api/game-user/invitation/getInvitationRewarded');
}
export function createReffCode(data) {
  return fetchPost('/bits-api/game-user/invitation/save', data);
}
export function getCodeLinkList(data) {
  return fetchPost('/bits-api/game-user/invitation/getInvitationCodePageList', data);
}

export function getDragonOpenRets(data) {
  return fetchPost('/game-api/dragonOpenRets', data);
}
export function betLogs(data) {
  return fetchPost('/game-api/dragonLogs', data);
}
export function getReferredUsers(data) {
  return fetchGet('/bits-api/game-user/invitation/getReferredUsers', data);
}
export function getAnchorReferredUsers(data) {
  return fetchGet('/bits-api/game-user/anchorUser/getReferredUsers', data);
}
export function getAnchorInvitationCode(data) {
  return fetchGet('/bits-api/game-user/anchorUser/getAnchorInvitationCode', data);
}

export function verifyValidatorCode(data) {
  return fetchPost('/game-api/verifyValidatorCode', data);
}
export function validatorQrcode(data) {
  return fetchPost('/game-api/validatorQrcode', data);
}
export function completeValidator(data) {
  return fetchPost('/game-api/completeValidator', data);
}
export function secureValidator(data) {
  return fetchPost('/game-api/secureValidator', data);
}
export function getLoginHistory() {
  return fetchGet('/bits-api/game-user/userCentre/getLoginHistory',{},{ headers: { isLoading: false }});
}
