import { INPUT_NUMBER_REG, INT_REG, INT_REG_WEB, NUMBER_REG, NUMBER_REG_DECIMAL } from './regExp';

//Parameter conversion processing
export function stringify(obj) {
  let str = '';
  for (let i in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, i)) {
      str += i + '=' + unescape(obj[i]) + '&';
    }
  }
  return str.slice(0, -1);
}

export function isUndefinedNull(value) {
  return typeof value === 'undefined' || value === null;
}

export function formatDataShow(value) {
  return isUndefinedNull(value) ? '-' : value;
}

export function toObject(params) {
  return JSON.parse(
    JSON.stringify(
      params,
      (_, value) => (typeof value === 'bigint' ? value.toString() : value) // return everything else unchanged
    )
  );
}

/*Determine safari browser*/
const isSafari = () => {
  return /Apple/.test(navigator.vendor);
};

/*Time Formatting*/
export function formatDate(dateStr, format = 'yyyy-MM-dd hh:mm:ss') {
  if (!dateStr) {
    return '';
  }
  // @ts-ignore
  if (isSafari() && isNaN(dateStr)) {
    dateStr = String(dateStr).replace(/(\d{2})(\d{2})$/, '$1:$2');
  }
  if (/^\d+$/g.test(String(dateStr))) {
    dateStr = Number(dateStr);
  }
  let dateObejct = new Date(dateStr);
  let date = {
    'M+': dateObejct.getMonth() + 1,
    'd+': dateObejct.getDate(),
    'h+': dateObejct.getHours(),
    'm+': dateObejct.getMinutes(),
    's+': dateObejct.getSeconds(),
    'q+': Math.floor((dateObejct.getMonth() + 3) / 3),
    'S+': dateObejct.getMilliseconds()
  };
  if (/(y+)/i.test(format)) {
    format = format.replace(RegExp.$1, (dateObejct.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (let k in date) {
    if (date.prototype.hasOwnProperty.call(date, k)) {
      if (new RegExp('(' + k + ')').test(format)) {
        format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? date[k] : ('00' + date[k]).substr(('' + date[k]).length));
      }
    }
  }
  return format;
}

export function isNumber(str) {
  return new RegExp(NUMBER_REG, 'gi').test(str);
}

export function isIntNumber(str) {
  return new RegExp(INT_REG, 'gi').test(str);
}
export function isIntNumberAndDecil(str) {
  return new RegExp(NUMBER_REG_DECIMAL, 'gi').test(str);
}

export function isIntNumberWeb(str) {
  return new RegExp(INT_REG_WEB, 'gi').test(str);
}

export function isInputNumber(str) {
  return new RegExp(INPUT_NUMBER_REG, 'gi').test(str);
}

export function isEmptyObject(data) {
  return !data || Object.keys(data).length === 0;
}

/*Dynamic Strings
 * @param str Dynamic Strings
 * @param obj Object
 */
export const regExpTemplate = (str, obj) => {
  return str.replace(/\${[^}]+}/g, (variableStr) => {
    let variable = variableStr.replace(/\${(.+)}/, '$1');
    return obj[variable] || '';
  });
};

//async await Error Handling
export function awaitWrap(promise) {
  return promise.then((data) => [data, null]).catch((err) => [null, err]);
}

/*Formatted Address*/
export function formatAddress(address, start = 6, end = 4) {
  if (!address) {
    return '';
  }
  let reg = new RegExp(`(.{${start}}).+(.{${end}}$)`, 'g');
  return address.replace(reg, '$1...$2');
}

export function getEventParentElement(element, targetId) {
  if (!element || element.tagName === 'BODY') {
    return null;
  } else if (element.id === targetId) {
    return element;
  } else {
    return getEventParentElement(element.parentElement, targetId);
  }
}

export function getObjFromSessionStorage(key) {
  let cacheStr = localStorage.getItem(key);
  if (!cacheStr || cacheStr === 'undefined') {
    return null;
  }
  return JSON.parse(cacheStr);
}

export function getObjFromLocalStorage(key) {
  let cacheStr = localStorage.getItem(key);
  return cacheStr ? JSON.parse(cacheStr) : null;
}

/*Verify email*/
export function isEmail(email) {
  return /[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}/g.test(email);
}

/*Fuzzy Email*/
export function encryptEmail(str) {
  if (!str) {
    return '--';
  }
  return str.replace(/(.+?).*(.+?@.+)/g, '$1****$2');
}

//Verify Phone
export function isMobile(phone) {
  return /^\d{7,}/g.test(phone);
}

export async function copyToClipboard(str) {
  if (navigator.clipboard && navigator.permissions) {
    await navigator.clipboard.writeText(str);
  } else {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
}

export function formatLocalDate(time) {
  return new Date(time).toLocaleDateString();
}

export function formatLocalTime(time) {
  return new Date(time).toLocaleTimeString();
}

export function ScrollTop() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.scrollTo(0, 0);
}

export function isAddress(str, addrType) {
  let reg;
  // let btc_reg = '^[1][a-km-zA-HJ-NP-Z1-9]{25,34}$' + '|' + '^[3][a-km-zA-HJ-NP-Z1-9]{25,34}$' + '|' + '^(bc1q|bc1p)[a-z0-9]{0,99}$';
  let btc_reg = '^[3][a-km-zA-HJ-NP-Z1-9]{25,34}$' + '|' + '^(bc1p)[a-z0-9]{0,97}$';
  if (addrType == 'SOL') reg = new RegExp('^[0-9A-Za-z]{44}$');
  else if (addrType === 'BTC') reg = new RegExp(btc_reg);
  else reg = new RegExp('^0x[0-9a-fA-F]{40}$');
  if (reg.test(str)) {
    return true;
  } else {
    return false;
  }
}

export function countDown(endTimeStamp) {
  let nowTimeStamp = new Date().getTime();
  let time;
  if (endTimeStamp > nowTimeStamp) {
    let mss = endTimeStamp - nowTimeStamp;
    let days = parseInt(`${mss / (1000 * 60 * 60 * 24)}`);
    let hours = parseInt(`${(mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)}`);
    let minutes = parseInt(`${(mss % (1000 * 60 * 60)) / (1000 * 60)}`);
    let seconds = parseInt(`${(mss % (1000 * 60)) / 1000}`);
    time = {
      day: { count: days < 10 ? '0' + days : `${days}`, unit: 'days' },
      hour: { count: hours < 10 ? '0' + hours : `${hours}`, unit: 'hours' },
      minute: { count: minutes < 10 ? '0' + minutes : `${minutes}`, unit: 'minutes' },
      second: { count: seconds < 10 ? '0' + seconds : `${seconds}`, unit: 'seconds' }
      // mss: mss,
    };
  } else {
    time = {
      day: { count: '00', unit: 'days' },
      hour: { count: '00', unit: 'hours' },
      minute: { count: '00', unit: 'minutes' },
      second: { count: '00', unit: 'seconds' }
      // mss: '00',
    };
  }
  return time;
}

/**  */
export function myFixed(n, bit) {
  let num = parseFloat(n);
  if (isNaN(num)) num = 0;
  let str = num.toString();
  //
  if (str.indexOf('.') < 0) {
    return num.toFixed(bit);
  }

  if (str.indexOf('.' > 0)) {
    let arr = str.split('.');
    if (arr[1].length > bit) {
      let sp = '.';
      sp += arr[1].slice(0, bit) + '0';
      let newStr = arr[0] + sp;
      return parseFloat(newStr).toFixed(bit);
    }
    if (arr[1].length <= bit) {
      return num.toFixed(bit);
    }
  }
}

export function parseQueryString(queryString) {
  if (queryString.indexOf('=') < 0) {
    return false;
  }
  const params = {};
  const paramPairs = queryString.slice(1).split('&');

  paramPairs.forEach((pair) => {
    const [key, value] = pair.split('=');
    const decodedKey = decodeURIComponent(key);
    const decodedValue = decodeURIComponent(value);
    params[decodedKey] = decodedValue;
  });

  return params;
}
