import {
  Bronze_1,
  Bronze_2,
  Bronze_3,
  Bronze_4,
  Bronze_5,
  Diamond_1,
  Diamond_2,
  Diamond_3,
  Diamond_4,
  Diamond_5,
  Gold_1,
  Gold_2,
  Gold_3,
  Gold_4,
  Gold_5,
  Platinum_1,
  Platinum_2,
  Platinum_3,
  Platinum_4,
  Platinum_5,
  Silver_1,
  Silver_2,
  Silver_3,
  Silver_4,
  Silver_5,
  Orichalcum,
  Mithril
} from 'static/images';

import { toThousands } from 'utils/commonUtils';

const color = {
  Bronze: '#CE7D4B',
  Silver: '#B1BECC',
  Gold: '#E9DA48',
  Platinum: '#42A1BC',
  Diamond: '#DD8681',
  Orichalcum: '#57F2D8',
  Mithril: '#8DCCFF'
};

const subColor = {
  Bronze: '#6C3514',
  Silver: '#555555',
  Gold: '#9F522E',
  Platinum: '#1A5475',
  Diamond: '#A73381',
  Orichalcum: '#A288E0',
  Mithril: '#D765A4'
};

const levelList = [
  {
    name: 'Bronze',
    id: 1,
    color: '#A15C36',
    image: Bronze_1,
    children: [
      {
        name: 'Bronze 1',
        image: Bronze_1,
        num: 10000
      },
      {
        name: 'Bronze 2',
        image: Bronze_2,
        num: 15000
      },
      {
        name: 'Bronze 3',
        image: Bronze_3,
        num: 20000
      },
      {
        name: 'Bronze 4',
        image: Bronze_4,
        num: 25000
      },
      {
        name: 'Bronze 5',
        image: Bronze_5,
        num: 30000
      }
    ]
  },
  {
    name: 'Silver',
    id: 2,
    color: '#6C86A4',
    image: Silver_1,
    children: [
      {
        name: 'Silver 1',
        image: Silver_1,
        num: 50000
      },
      {
        name: 'Silver 2',
        image: Silver_2,
        num: 75000
      },
      {
        name: 'Silver 3',
        image: Silver_3,
        num: 100000
      },
      {
        name: 'Silver 4',
        image: Silver_4,
        num: 125000
      },
      {
        name: 'Silver 5',
        image: Silver_5,
        num: 150000
      }
    ]
  },
  {
    name: 'Gold',
    id: 3,
    color: '#F4C826',
    image: Gold_1,
    children: [
      {
        name: 'Gold 1',
        image: Gold_1,
        num: 200000
      },
      {
        name: 'Gold 2',
        image: Gold_2,
        num: 300000
      },
      {
        name: 'Gold 3',
        image: Gold_3,
        num: 400000
      },
      {
        name: 'Gold 4',
        image: Gold_4,
        num: 500000
      },
      {
        name: 'Gold 5',
        image: Gold_5,
        num: 600000
      }
    ]
  },
  {
    name: 'Platinum',
    id: 4,
    color: '#259AA4',
    image: Platinum_1,
    children: [
      {
        name: 'Platinum 1',
        image: Platinum_1,
        num: 750000
      },
      {
        name: 'Platinum 2',
        image: Platinum_2,
        num: 1000000
      },
      {
        name: 'Platinum 3',
        image: Platinum_3,
        num: 1500000
      },
      {
        name: 'Platinum 4',
        image: Platinum_4,
        num: 2500000
      },
      {
        name: 'Platinum 5',
        image: Platinum_5,
        num: 5000000
      }
    ]
  },
  {
    name: 'Diamond',
    id: 5,
    color: '#ED7F80',
    image: Diamond_1,
    children: [
      {
        name: 'Diamond 1',
        image: Diamond_1,
        num: 10000000
      },
      {
        name: 'Diamond 2',
        image: Diamond_2,
        num: 25000000
      },
      {
        name: 'Diamond 3',
        image: Diamond_3,
        num: 50000000
      },
      {
        name: 'Diamond 4',
        image: Diamond_4,
        num: 100000000
      },
      {
        name: 'Diamond 5',
        image: Diamond_5,
        num: 250000000
      }
    ]
  },
  {
    name: 'Orichalcum',
    id: 6,
    color: '#57F2D8',
    image: Orichalcum,
    children: []
  },
  {
    name: 'Mithril',
    id: 7,
    color: '#8DCCFF',
    image: Mithril,
    children: []
  }
];

const getVipLevel = (num) => {
  const value = Number(num);
  let levelImage = Bronze_1;
  let level = 'Bronze 1';
  let nextlevel = 'Bronze 2';
  let nextlevelImage = Bronze_2;
  let nextLevelNum = 0;
  let progressNum = 0;
  if (0 <= value && value <= 10000) {
    level = 'Bronze 1';
    levelImage = Bronze_1;
    nextlevel = 'Bronze 2';
    nextlevelImage = Bronze_2;
    nextLevelNum = 10000 - value;
    progressNum = (value - 0) / 10000;
  } else if (10000 < value && value <= 15000) {
    level = 'Bronze 1';
    levelImage = Bronze_1;
    nextlevel = 'Bronze 2';
    nextlevelImage = Bronze_2;
    nextLevelNum = 15000 - value;
    progressNum = (value - 10000) / 5000;
  } else if (15000 < value && value <= 20000) {
    level = 'Bronze 2';
    levelImage = Bronze_2;
    nextlevel = 'Bronze 3';
    nextlevelImage = Bronze_3;
    nextLevelNum = 20000 - value;
    progressNum = (value - 15000) / 5000;
  } else if (20000 < value && value <= 25000) {
    level = 'Bronze 3';
    levelImage = Bronze_3;
    nextlevel = 'Bronze 4';
    nextlevelImage = Bronze_4;
    nextLevelNum = 25000 - value;
    progressNum = (value - 20000) / 5000;
  } else if (25000 < value && value <= 30000) {
    level = 'Bronze 4';
    levelImage = Bronze_4;
    nextlevel = 'Bronze 5';
    nextlevelImage = Bronze_5;
    nextLevelNum = 30000 - value;
    progressNum = (value - 25000) / 5000;
  } else if (30000 < value && value <= 50000) {
    level = 'Bronze 5';
    levelImage = Bronze_5;
    nextlevel = 'Silver 1';
    nextlevelImage = Silver_1;
    nextLevelNum = 50000 - value;
    progressNum = (value - 30000) / 20000;
  } else if (50000 < value && value <= 75000) {
    level = 'Silver 1';
    levelImage = Silver_1;
    nextlevel = 'Silver 2';
    nextlevelImage = Silver_2;
    nextLevelNum = 75000 - value;
    progressNum = (value - 50000) / 25000;
  } else if (75000 < value && value <= 100000) {
    level = 'Silver 2';
    levelImage = Silver_2;
    nextlevel = 'Silver 3';
    nextlevelImage = Silver_3;
    nextLevelNum = 100000 - value;
    progressNum = (value - 75000) / 25000;
  } else if (100000 < value && value <= 125000) {
    level = 'Silver 3';
    levelImage = Silver_3;
    nextlevel = 'Silver 4';
    nextlevelImage = Silver_4;
    nextLevelNum = 125000 - value;
    progressNum = (value - 100000) / 25000;
  } else if (125000 < value && value <= 150000) {
    level = 'Silver 4';
    levelImage = Silver_4;
    nextlevel = 'Silver 5';
    nextlevelImage = Silver_5;
    nextLevelNum = 150000 - value;
    progressNum = (value - 125000) / 25000;
  } else if (150000 < value && value <= 200000) {
    level = 'Silver 5';
    levelImage = Silver_5;
    nextlevel = 'Gold 1';
    nextlevelImage = Gold_1;
    nextLevelNum = 200000 - value;
    progressNum = (value - 150000) / 50000;
  } else if (200000 < value && value <= 300000) {
    level = 'Gold 1';
    levelImage = Gold_1;
    nextlevel = 'Gold 2';
    nextlevelImage = Gold_2;
    nextLevelNum = 300000 - value;
    progressNum = (value - 200000) / 100000;
  } else if (300000 < value && value <= 400000) {
    level = 'Gold 2';
    levelImage = Gold_2;
    nextlevel = 'Gold 3';
    nextlevelImage = Gold_3;
    nextLevelNum = 400000 - value;
    progressNum = (value - 300000) / 100000;
  } else if (400000 < value && value <= 500000) {
    level = 'gold 3';
    levelImage = Gold_3;
    nextlevel = 'Gold 4';
    nextlevelImage = Gold_4;
    nextLevelNum = 500000 - value;
    progressNum = (value - 400000) / 100000;
  } else if (500000 < value && value <= 600000) {
    level = 'Gold 4';
    levelImage = Gold_4;
    nextlevel = 'Gold 5';
    nextlevelImage = Gold_5;
    nextLevelNum = 600000 - value;
    progressNum = (value - 500000) / 100000;
  } else if (600000 < value && value <= 750000) {
    level = 'Gold 5';
    levelImage = Gold_5;
    nextlevel = 'Platinum 1';
    nextlevelImage = Platinum_1;
    nextLevelNum = 750000 - value;
    progressNum = (value - 600000) / 150000;
  } else if (750000 < value && value <= 1000000) {
    level = 'Platinum 1';
    levelImage = Platinum_1;
    nextlevel = 'Platinum 2';
    nextlevelImage = Platinum_2;
    nextLevelNum = 1000000 - value;
    progressNum = (value - 750000) / 250000;
  } else if (1000000 < value && value <= 1500000) {
    level = 'Platinum 2';
    levelImage = Platinum_2;
    nextlevel = 'Platinum 3';
    nextlevelImage = Platinum_3;
    nextLevelNum = 1500000 - value;
    progressNum = (value - 1000000) / 500000;
  } else if (1500000 < value && value <= 2500000) {
    level = 'Platinum 3';
    levelImage = Platinum_3;
    nextlevel = 'Platinum 4';
    nextlevelImage = Platinum_4;
    nextLevelNum = 2500000 - value;
    progressNum = (value - 1500000) / 1000000;
  } else if (2500000 < value && value <= 5000000) {
    level = 'Platinum 4';
    levelImage = Platinum_4;
    nextlevel = 'Platinum 5';
    nextlevelImage = Platinum_5;
    nextLevelNum = 5000000 - value;
    progressNum = (value - 2500000) / 2500000;
  } else if (5000000 < value && value <= 10000000) {
    level = 'Platinum 5';
    levelImage = Platinum_5;
    nextlevel = 'Diamond 1';
    nextlevelImage = Diamond_1;
    nextLevelNum = 10000000 - value;
    progressNum = (value - 5000000) / 5000000;
  } else if (10000000 < value && value <= 25000000) {
    level = 'Diamond 1';
    levelImage = Diamond_1;
    nextlevel = 'Diamond 2';
    nextlevelImage = Diamond_2;
    nextLevelNum = 25000000 - value;
    progressNum = (value - 10000000) / 15000000;
  } else if (25000000 < value && value <= 50000000) {
    level = 'Diamond 2';
    levelImage = Diamond_2;
    nextlevel = 'Diamond 3';
    nextlevelImage = Diamond_3;
    nextLevelNum = 50000000 - value;
    progressNum = (value - 25000000) / 25000000;
  } else if (50000000 < value && value <= 100000000) {
    level = 'Diamond 3';
    levelImage = Diamond_3;
    nextlevel = 'Diamond 4';
    nextlevelImage = Diamond_4;
    nextLevelNum = 100000000 - value;
    progressNum = (value - 50000000) / 50000000;
  } else if (100000000 < value && value <= 250000000) {
    level = 'Diamond 4';
    levelImage = Diamond_4;
    nextlevel = 'Diamond 5';
    nextlevelImage = Diamond_5;
    nextLevelNum = 250000000 - value;
    progressNum = (value - 100000000) / 150000000;
  } else if (250000000 < value && value <= 500000000) {
    level = 'Diamond 5';
    levelImage = Diamond_5;
  } else if (500000000 < value && value <= 1000000000) {
    level = 'Orichalcum';
  } else if (1000000000 < value) {
    level = 'Mithril';
  }
  const levelFontColor = level.split(' ')[0];
  const nextLevelColor = level.split(' ')[0];
  return {
    levelImage,
    level,
    nextlevel,
    nextlevelImage,
    nextLevelNum: toThousands(nextLevelNum.toFixed(2)),
    progressNum,
    levelColor: color[levelFontColor],
    subColor: subColor[levelFontColor],
    nextLevelColor: color[nextLevelColor]
  };
};

export { getVipLevel, levelList };
