import React, { useState } from 'react';
import './index.scss';


import VerifyModel from './verifyModel';
import { useNavigate } from 'react-router';

import { footSimple, footVerified, footLisence, logo } from 'static/images';

function Footer({ matchDownMd }) {
  const navigate = useNavigate();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const jumpList = [
    {
      title: 'Support',
      child: [
        { title: 'Help Centre', url: '/info/game_help-centre' },
        { title: 'Verify Representative', isModel: true }
      ]
    },
    {
      title: 'Policy',
      child: [
        { title: 'Terms of Service', url: '/info/game_terms' },
        { title: 'Privacy policy', url: '/info/game_privacy' },
        { title: 'AML Policy', url: '/info/game_AML-Policy' },
        { title: 'Licence', url: '/info/game_licence' }
      ]
    },
    {
      title: 'Community',
      child: [
        { title: 'Twitter', link: 'https://x.com/21bitsofficial?s=20' },
        { title: 'Facebook', link: 'https://www.facebook.com/21bitsofficial' },
        { title: 'Telegram', link: 'https://t.me/twentyonexbits' },
        { title: 'Discord', link: 'https://discord.com/invite/S5qvGCGhBY' }
      ]
    }
  ];

  const handleJump = (item) => {
    console.log(item);
    if (item.link) {
      return window.open(item.link, '_blank');
    }
    if (item.url) {
      return navigate(item.url);
    }
    if (item.isModel) {
      return setIsOpenDialog(true);
    }
  };

  const handleGoLicense = () => {
    window.open('https://curacao-gaming.net/', '_blank');
  };
  return (
    <div className="footer-box">
      <div
        className="footer-content"
        style={{
          marginTop: 'auto'
          // padding: matchDownMd ? '10px 10px 40px 10px' : '50px 200px'
        }}
      >
        <div className="footer-content-main">
          <div className="footer-content-logo">
            <div className="footer-logo">
              <img src={logo} alt="logo" />
            </div>
            <div className="footer-text">
              21BITS is owned and operated by Deck Entertainment B.V., established under the laws of Curaçao with company registration
              number 144851. Deck Entertainment B.V. holds the E-gaming licence number 5536/JAZ issued by Licensing N.V., authorised by the
              Curaçao government. Note: Players are responsible for understanding the current laws and regulations regarding online gambling
              in their respective jurisdictions.
            </div>
            <div className="other-logo">
              {/* <img src={footVerified} alt="footSimple" className="foot-simple" /> */}
              {/* <img src={footSimple} alt="footSimple" onClick={handleGoLicense} className="foot-simple" /> */}
              {/* <img src={footVerified} alt="footVerified" className="foot-verified" /> */}

              {/* <img src={footLisence} alt="footSimple" onClick={handleGoLicense} className="foot-simple" /> */}
              <div>18+</div>
            </div>
          </div>
          {jumpList.map((item, index) => (
            <div key={index} className="footer-link">
              <div className="footer-content-title">{item.title}</div>
              <div className="footer-content-item">
                {item.child.map((itemChild, indexChild) => (
                  <p key={indexChild} onClick={() => handleJump(itemChild)}>
                    {itemChild.title}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* <div className="dir">
          21BITS is owned and operated by Deck Entertainment B.V., established under the laws of Curaçao with company registration number
          144851. Deck Entertainment B.V. holds the E-gaming licence number 5536/JAZ issued by Licensing N.V., authorised by the Curaçao
          government. Note: Players are responsible for understanding the current laws and regulations regarding online gambling in their
          respective jurisdictions.
        </div>
        <div className="flag_icon">
          <div className="f-icon">
            <div style={{ color: 'rgb(155, 165, 180)' }}>© 2023 21bits.io | All Rights Reserved</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ color: '#555', fontSize: '30px', fontFamily: 'Geogrotesque Bl', fontWeight: '600', marginLeft: '20px' }}>
                {' '}
                18+{' '}
              </div>
              <img src={footLogo1} alt="" style={{ marginLeft: '20px', width: '30px', height: '30px' }} />
              <img src={footLogo2} alt="" style={{ marginLeft: '20px', width: '30px', height: '30px' }} />
            </div>
          </div>
        </div> */}
      </div>

      <VerifyModel isOpenDialog={isOpenDialog} setIsOpenDialog={(val) => setIsOpenDialog(val)} />
    </div>
  );
}

export default Footer;
