import { fetchPost, fetchGet } from '../useRequest';

export function generateNonce(address) {
  return fetchPost('/bits-api/game-user/app-api/v2/auth/generateNonce', { address });
}

export function generateLoginNonce(address) {
  return fetchPost('/bits-api/game-user/app-api/v2/auth/generateNonce', { wallet_addr: address });
}

export function loginUseWallet(address, signature, type) {
  return fetchPost('/bits-api/game-user/app-api/v2/auth/walletToken', {
    address,
    signature,
    type
  });
}
export function registerAccount(data) {
  return fetchPost('/bits-api/game-user/app-api/v2/newuser/register', data);
}
export function walletRegistration(data) {
  return fetchPost('/bits-api/game-user/app-api/v2/newuser/walletRegistration', data);
}
export function loginAccount({ account, password, type,code }) {
  return fetchPost('/bits-api/game-user/app-api/v2/auth/token', { username: account, password, type,code });
}
export const userInfo = () => {
  return fetchGet('/bits-api/game-user/userCentre/userInfo', {});
};
export const logout = () => {
  return fetchPost('/bits-api/game-user/userCentre/logout', {});
};
export const getUserAssets = () => {
  return fetchGet('/bits-api/game-wallet/asset/getUserAssets', {});
};

export const withdraw = (data) => {
  return fetchPost('/bits-api/game-wallet/asset/withdraw', data);
};

export const getSymbolPrice = () => {
  return fetchGet('/bits-api/game-wallet/coin/getSymbolPrice', {});
};
export const getSolPrice = () => {
  return fetchGet('/bits-api/game-wallet/asset/getBSolPrice', {});
};
export const getBtcPrice = () => {
  return fetchGet('/bits-api/game-wallet/btc/getBtcPrice', {});
};
export const getTrumpPrice = () => {
  return fetchGet('/bits-api/game-wallet/asset/getTrumpPrice');
};
export const getEthPrice = () => {
  return fetchGet('/bits-api/game-wallet/asset/getEthPrice', {});
};
export const getBNBPrice = () => {
  return fetchGet('/bits-api/game-wallet/asset/getBnbPrice', {}, { headers: { isLoading: false } });
};

export const getUserDepositNumber = () => {
  return fetchGet('/bits-api/game-wallet/depositActivity/getUserDepositNumber', {}, { headers: { isLoading: false } });
};

export const getEthInternalTransaction = () => {
  return fetchGet('/bits-api/game-wallet/depositRecord/ethInternalTransaction', {}, { headers: { isLoading: false } });
};

export const synchronousSolDeposit = (data) => {
  return fetchPost('/bits-api/game-wallet/asset/synchronousSolDeposit', data);
};

export const getSupportedNetworks = () => {
  return fetchGet('/bits-api/game-wallet/asset/getNetworks', {});
};

export const getCurrencyList = () => {
  return fetchGet('/bits-api/game-wallet/coin/list', {});
};

export const getETHgas = () => {
  return fetchGet('/bits-api/game-wallet/asset/getEthGasPrice', {});
};
export const getBTCgas = () => {
  return fetchGet('/bits-api/game-wallet/btc/getBtcFee', {});
};
export const getErcSymbolList = () => {
  return fetchGet('/bits-api/game-wallet/coin/getErcSymbolList', {});
};

export const sendVerifyCode = (data) => {
  return fetchPost('/bits-api/game-wallet/asset/withdrawEmailCode', data);
};

export const getUserRealName = () => {
  return fetchGet('/bits-api/game-user/userCentre/userInfo', {});
};

export const getAreaList = () => {
  return fetchGet('/bits-api/game-user/areaCode/list', {});
};
export const getUserCouponsHistoryList = () => {
  return fetchGet('/bits-api/game-user/coupons/getUserCouponsHistoryList', {});
};

export const addUserRealName = (data) => {
  return fetchPost('/bits-api/game-user/real/saveRealName', data);
};

export const bingInviteCode = (data) => {
  return fetchPost('/bits-api/game-user/invitation/addBindRelation', data);
};
export const claimCoupons = (data) => {
  return fetchPost('/bits-api/game-user/coupons/claimCoupons', data);
};

export const accountCoupons = (data) => {
  return fetchPost('/bits-api/game-wallet/asset/claimCoupons', data);
};

export const claimCouponsReward = (data) => {
  return fetchPost('/bits-api/game-user/coupons/claimCouponsReward', data);
};
export const getUserCouponsTaskList = (data) => {
  return fetchGet('/bits-api/game-user/coupons/getUserCouponsTaskList', data);
};

export const getDepositActivity = (data) => {
  return fetchGet('/bits-api/game-wallet/depositActivity/getDepositActivity', data);
};

export const claimDepositActivity = (data) => {
  return fetchGet('/bits-api/game-wallet/depositActivity/claimDepositActivity', data);
};
export const getDepositRecordPageList = (data) => {
  return fetchGet('/bits-api/game-wallet/depositActivity/getDepositRecordPageList', data);
};

export const getThreshold = () => {
  return fetchGet('/bits-api/game-wallet/asset/getWithdrawalThreshold', {});
};

export function getDepositGive(data) {
  return fetchGet('/bits-api/game-wallet/asset/getDepositGive', data);
}
export function claimDepositGive(data) {
  return fetchPost('/bits-api/game-wallet/asset/claimDepositGive', data);
}

//lottery
export const rankRaffle = (data) => {
  return fetchPost('/game-api/rankRaffle', data);
};

//binaryOption
export const predictLogs = (data) => {
  return fetchPost('/game-api/predictLogs', data);
};
export const rankPredict = (data) => {
  return fetchPost('/game-api/rankPredict', data);
};

//setEvents
export const marketStatis = (data) => {
  return fetchPost('/game-api/marketStatis', data);
};

//binaryOptionOne
export const predictOneLogs = (data) => {
  return fetchPost('/game-api/predictOneLogs', data);
};
export const rankPredictOne = (data) => {
  return fetchPost('/game-api/rankPredictOne', data);
};
export const tradeSymbolsList = () => {
  return fetchPost('/game-api/trade/tradeSymbols');
};

export const getBitsBd = (data) => {
  return fetchPost(`/bits-api/game-user/userCentre/getBitsBd`, data);
};
