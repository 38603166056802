import { fetchPost, fetchGet } from '../useRequest';

export const getTotalRewarded = () => {
  return fetchGet('/bits-api/game-wallet/rebate/getTotalRewarded', {});
};

export const getTodayClaimedReward = () => {
  return fetchGet('/bits-api/game-wallet/rebate/getTodayClaimedReward', {});
};

export const receiveClaimed = (data) => {
  return fetchPost('/bits-api/game-wallet/rebate/claimedRebateCalendar', data);
};

export const getReceivePercent = (data) => {
  return fetchGet('/bits-api/game-wallet/rebate/getFlowingRewardsCount', data);
};

export const getRebateRecordFlag = (data) => {
  return fetchGet('/bits-api/game-wallet/rebate/getRebateRecordFlag', data);
};
export const getUserMonthWhetherClaim = (data) => {
  return fetchGet('/bits-api/game-wallet/rebate/getUserMonthWhetherClaim', data);
};
export const getUserDailyWhetherClaim = (data) => {
  return fetchGet('/bits-api/game-wallet/rebate/getUserOneWhetherClaim', data);
};
export const getUserWeekWhetherClaim = (data) => {
  return fetchGet('/bits-api/game-wallet/rebate/getUserSevenWhetherClaim', data);
};

export const addRebateRecord = (data) => {
  return fetchGet('/bits-api/game-wallet/rebate/addRebateRecord', data);
};
export const addUserDayRewards = (data) => {
  return fetchGet('/bits-api/game-wallet/rebate/addUserDayRewards', data);
};
export const addUserMonthRewards = (data) => {
  return fetchGet('/bits-api/game-wallet/rebate/addUserMonthRewards', data);
};
export const addUserWeeklyRewards = (data) => {
  return fetchGet('/bits-api/game-wallet/rebate/addUserWeeklyRewards', data);
};
