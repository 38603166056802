import React, { useEffect, useState, useRef } from 'react';
import { toThousands, getImageUrl } from 'utils/commonUtils';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

import { EmptSlot } from 'static/images';

import { BET_ALL_RECORD_LIST } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { goGame } from 'utils/threeGames';
import './index.scss';

const Broadcast = ({ isShow }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mapGame = window.localStorage.getItem('mapGameList');
  const mapGameList = JSON.parse(mapGame || '[]');
  const betHistory = useSelector((state) => state.customization.betHistory);
  const isLogin = useSelector((state) => state.customization.isLogin);
  const [list, setList] = useState([]);
  const [animating, setAnimating] = useState(false);
  const readyList = useRef([]);
  const noiceListReady = useRef([]);
  const allRecordList = useRef([]);
  const listRef = useRef([]);
  const intervalId = useRef(null);

  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.down('md'));
  const num = !matchUpMd ? 15 : 5;
  useEffect(() => {
    if (isLogin === 0) return;
    init();
    starx.on('cmd_betmsg', (res) => {
      dealList(res.batch_msg);
    });
    intervalId.current = setInterval(() => {
      setAnimating(true);
      setTimeout(() => {
        pushWindata();
        pushBetRecord();
      }, 1000);
    }, 1000);
    return () => clearInterval(intervalId.current);
  }, [isLogin]);
  function init() {
    const list = betHistory.filter((item) => item.bet.pay_out * 1 > 0).slice(0, num);
    listRef.current = list;
    allRecordList.current = list;
    dispatch({ type: BET_ALL_RECORD_LIST, betAllRecordList: list });
    setList(list);
  }
  function pushBetRecord() {
    const data = noiceListReady.current[0];
    if (!data) return;
    const noiceListReadyData = [...noiceListReady.current];
    const noiceListData = [data, ...allRecordList.current];
    noiceListReadyData.shift();
    noiceListData.pop();
    noiceListReady.current = noiceListReadyData;
    allRecordList.current = noiceListData;
    dispatch({ type: BET_ALL_RECORD_LIST, betAllRecordList: noiceListData });
  }
  function pushWindata() {
    const data = readyList.current[0];
    if (!data) return;

    const datalist = [data, ...listRef.current];
    const readyListData = [...readyList.current];
    datalist.pop();
    readyListData.shift();

    listRef.current = datalist;
    readyList.current = readyListData;
    setList(datalist);
    setAnimating(false);
  }
  function dealList(data) {
    const list = data.map((item) => {
      const { game_id, game_name } = item.bet;
      const mapGameName = mapGameList[game_id] && mapGameList[game_id].split('_')[0];
      const suffix = mapGameList[game_id] ? mapGameList[game_id].split('_')[1] : 'webp';
      return {
        ...item,
        game_name: game_name || mapGameName || '',
        key: Math.random().toString().slice(3, 8),
        suffix,
        game_id
      };
    });
    const winList = list.filter((item) => item.bet.pay_out * 1 > 0);
    readyList.current = readyList.current.concat(winList);
    noiceListReady.current = noiceListReady.current.concat(list);
    // setReadyList(readyList.concat(winList));
    // dispatch({ type: BET_RECORD_LIST, betRecordList: list });
    // dispatch({ type: BET_ALL_RECORD_LIST, betAllRecordList: list });
  }
  return (
    <div className="betMsgWin" style={{ display: isShow ? 'none' : 'block' }}>
      <div className="flag">
        <div style={{ width: '8px', height: '8px', background: 'rgb(255, 73, 73)', borderRadius: '50%', marginRight: '4px' }}></div>
        <div>LIVE WINS</div>
      </div>
      <div className="contentBox">
        {list.map((item, index) => {
          return (
            <div
              className="history-item swiper-no-swiping"
              key={index}
              style={{ cursor: 'pointer' }}
              onClick={() => goGame(item, navigate)}
            >
              <div className={`history-card ${animating ? 'history-card-transition' : ''}`}>
                <img
                  src={getImageUrl(item.brand, item)}
                  onError={(e) => {
                    e.target.src = EmptSlot;
                    e.target.onerror = null;
                  }}
                  // width={75}
                  style={{ borderRadius: '3px', width: '55px', height: '70px', objectFit: 'cover' }}
                />
                <div className="userInfo">
                  <img
                    src={`https://s3.21bits.io/front_static/assets/images/vip_level/${item.lv}.png`}
                    alt=""
                    width={15}
                    onError={(e) => {
                      e.target.src = `https://s3.21bits.io/front_static/assets/images/vip_level/Bronze_1.png`;
                      e.target.onError = null;
                    }}
                  />
                  <div className="nickName">{item.name}</div>
                </div>
                <div className="wager">${toThousands(item.bet.pay_out)}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Broadcast;
