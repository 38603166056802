import react, { useState, useEffect } from 'react';

import { useTheme } from '@mui/material/styles';
import { Box, Tooltip, Avatar, Chip, Accordion, AccordionSummary, AccordionDetails, useMediaQuery } from '@mui/material';
import './index.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import Menu from './compentent/MenuItem';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { PROFILE_ID } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { MENU_ITEM, SIDEBAR_MENU } from 'store/actions';

import { collClose, collOpen } from 'static/images';

import { MOBILE_MENU_OPEN } from 'store/actions';
// import { useSnackbar } from 'notistack';
import { enqueueSnackbar } from 'notistack';

import Footer from './compentent/footer';

const BootAccordion = styled(Accordion)(({ theme }) => ({
  '& .MuiButtonBase-root .Mui-expanded': {
    margin: 0
  },
  '&.Mui-expanded': {
    margin: 0
  }
}));

const Sidebar = (props) => {
  const [expanded, setExpanded] = useState('');
  const [menuItemUrl, setMenuItemUrl] = useState('');
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  // const { enqueueSnackbar } = useSnackbar();

  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const currentSidebarMenu = useSelector((state) => state.customization.sidebarMenu);
  const currentMenuItem = useSelector((state) => state.customization.menuItem);
  const isMenuOpen = useSelector((state) => state.customization.mobileMenuOpen);
  const userInfor = useSelector((state) => state.customization.userInfor);
  const token = useSelector((state) => state.customization.token);

  const handleChange = (val, isExpanded) => {
    console.log(val, '123');
    setExpanded(isExpanded ? val : false);
    dispatch({ type: SIDEBAR_MENU, sidebarMenu: val });
    window.localStorage.setItem('sidebarMenu', val);
  };

  const handleClickItem = (item, menuTitle) => {
    if (item.disable && userInfor.isInfluencer) return;
    dispatch({ type: MOBILE_MENU_OPEN, mobileMenuOpen: false });
    let isStream = process.env.REACT_APP_NODE_ENV === 'test' ? true : false;
    if (item.hiddenStream && isStream) {
      // navigate('/home');
      // enqueueSnackbar('COMING SOON', { variant: 'warning' });
      // dispatch({ type: SIDEBAR_MENU, sidebarMenu: '' });
      // dispatch({ type: MENU_ITEM, menuItem: '' });
      return;
    }
    setMenuItemUrl(item.url);
    if (item.url.includes('/game_profile')) {
      const profileId = item.url.split('/')[2];
      navigate('/game_profile');
      window.localStorage.setItem('PROFILE_ID', profileId);
      dispatch({ type: PROFILE_ID, profileId: profileId });
    } else {
      navigate(item.url);
    }

    dispatch({ type: MENU_ITEM, menuItem: item.url });
    window.localStorage.setItem('currentmenuItem', item.url);
    dispatch({ type: SIDEBAR_MENU, sidebarMenu: menuTitle });
    window.localStorage.setItem('sidebarMenu', menuTitle);
  };

  const handleTriggleColl = () => {
    const flag = !collapsed;
    setCollapsed(flag);
    window.localStorage.setItem('collapsed', flag);
  };

  const handleEnter = (e) => {
    e.target.style.color = '#5096FF';
  };
  const handleLeave = (e) => {
    e.target.style.color = '#576788';
  };

  useEffect(() => {
    setExpanded(currentSidebarMenu);
    setMenuItemUrl(currentMenuItem);
  }, [currentSidebarMenu, currentMenuItem]);

  const handleComing = (menu) => {
    console.log(menu);
    setExpanded(menu.title);

    if (menu.url) navigate(menu.url);
    else return enqueueSnackbar('COMING SOON', { variant: 'warning' });
  };

  useEffect(() => {
    const siderBox = document.getElementById('siderBox');
    if (collapsed) {
      siderBox.style.width = '36px';
      siderBox.style.transitionDuration = '300ms';
    } else {
      if (isMenuOpen && matchDownMd) siderBox.style.width = '100%';
      else {
        siderBox.style.width = '240px';
      }
      siderBox.style.transitionDuration = '300ms';
    }
  }, [collapsed, matchDownMd, isMenuOpen]);

  useEffect(() => {
    const flag = window.localStorage.getItem('collapsed');
    if (flag === 'true') setCollapsed(true);
    else setCollapsed(false);
  }, []);

  useEffect(() => {
    if (isMenuOpen) setCollapsed(false);
  }, [isMenuOpen]);

  return (
    <>
      <Box
        className={classnames('sidebar-box', {
          'show-mobile-box': isMenuOpen && matchDownMd,
          'hide-mobile-box': !isMenuOpen && matchDownMd
        })}
        style={{ background: props.sportView ? theme.mainBgColor.sportPath : theme.mainBgColor.default }}
        id="siderBox"
      >
        <Tooltip title={collapsed ? 'Expand' : 'Collapse'} placement="right">
          <Box
            className="sider-coll"
            sx={{ width: '36px', display: isMenuOpen && 'none !important', paddingRight: !collapsed && '8px !important' }}
          >
            <img src={collapsed ? collOpen : collClose} alt="close" className="coll-icon" onClick={handleTriggleColl} />
          </Box>
        </Tooltip>
        {!collapsed ? (
          <Box>
            {Menu.map((menu) => {
              if (!token && menu.needToken) return null;
              else if (menu.MenuItem && menu.MenuItem.length === 0)
                return (
                  <Box
                    onClick={() => handleComing(menu)}
                    className={classnames('accordion-title', { 'accordion-active': expanded === menu.title })}
                    key={menu.title}
                    sx={{
                      height: '36px',
                      cursor: 'pointer',
                      padding: '0 16px',
                      lineHeight: '36px',
                      borderBottom: '1px solid #141726',
                      '&:hover': {
                        background: '#233f6c',
                        color: '#fff',
                        svg: {
                          color: '#5096ff !important'
                        }
                      }
                    }}
                  >
                    <Box
                      className="title-box"
                      sx={{
                        '&:hover::before': { background: `url(${menu.iconOn}) no-repeat` },
                        '&::before': {
                          content: '""',
                          background: `url(${expanded === menu.title ? menu.iconOn : menu.icon}) no-repeat`,
                          marginRight: '9px',
                          width: '19px',
                          height: '19px'
                        }
                      }}
                    >
                      {menu.title}
                    </Box>
                  </Box>
                );
              else
                return (
                  <BootAccordion
                    key={menu.title}
                    // expanded={expanded === menu.title}
                    defaultExpanded
                    onChange={(e, isExpanded) => handleChange(menu.title, isExpanded)}
                  >
                    <AccordionSummary
                      className={classnames('accordion-title', { 'accordion-active': expanded === menu.title })}
                      sx={{ '& .MuiAccordionSummary-expandIconWrapper': { transitionDuration: '0s' } }}
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            width: '16px',
                            height: '16px',
                            background: '#00000044',
                            color: '#576788',
                            borderRadius: '4px'
                          }}
                          onMouseEnter={(e) => {
                            handleEnter(e);
                          }}
                          onMouseLeave={(e) => {
                            handleLeave(e);
                          }}
                        />
                      }
                    >
                      <Box
                        className="title-box"
                        sx={{
                          '&:hover::before': { background: `url(${menu.iconOn}) no-repeat` },
                          '&::before': {
                            content: '""',
                            background: `url(${expanded === menu.title ? menu.iconOn : menu.icon}) no-repeat`,
                            marginRight: '9px',
                            width: '19px',
                            height: '19px'
                          }
                        }}
                      >
                        {menu.title}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails
                      className="accordion-detail"
                      style={{ background: props.sportView ? theme.mainBgColor.sportPath : theme.mainBgColor.default }}
                    >
                      {menu.MenuItem
                        ? menu.MenuItem.map((item) => {
                            if (!token && item.label === 'FAVOURITES') return null;
                            else
                              return (
                                <Box
                                  key={item.label}
                                  className={classnames('accordion-item', {
                                    'item-active accordion-item-active': menuItemUrl === item.url
                                  })}
                                  sx={{
                                    '&:hover::before': { background: `url(${item.iconOn}) no-repeat` },
                                    '&::before': {
                                      content: '""',
                                      background: `url(${menuItemUrl === item.url ? item.iconOn : item.icon}) no-repeat`,
                                      marginRight: '9px',
                                      width: '19px',
                                      height: '19px'
                                    }
                                  }}
                                  onClick={() => {
                                    handleClickItem(item, menu.title);
                                  }}
                                >
                                  {item.label}
                                </Box>
                              );
                          })
                        : menu.eleCopt && menu['eleCopt']()}
                    </AccordionDetails>
                  </BootAccordion>
                );
            })}
          </Box>
        ) : (
          <Box>
            {Menu.map((menu) => {
              if (!token && menu.needToken) return null;
              else
                return (
                  <Box key={menu.title}>
                    <Tooltip title={menu.title} placement="right" key={menu.title}>
                      <Box
                        className={classnames('accordion-title', { 'accordion-active': expanded === menu.title })}
                        sx={{
                          height: '44px',
                          '&:hover::before': { background: `url(${menu.iconOn}) no-repeat center center` },
                          '&::before': {
                            content: '""',
                            display: 'block',
                            background: `url(${expanded === menu.title ? menu.iconOn : menu.icon}) no-repeat center center`,
                            width: '36px',
                            height: '36px'
                          }
                        }}
                        onChange={() => handleChange(menu.title, true)}
                      ></Box>
                    </Tooltip>
                    {menu.MenuItem &&
                      menu.MenuItem.map((item) => (
                        <Tooltip title={item.label} placement="right" key={item.label}>
                          <Box
                            className={classnames('coll-menu-item', { 'item-active': menuItemUrl === item.url })}
                            sx={{
                              display: 'block',
                              '&:hover::before': {
                                background: 'rgba(166, 255, 112, 0.05)',
                                background: `url(${item.iconOn}) no-repeat center center`
                              },
                              '&::before': {
                                display: 'flex',
                                justifyContent: 'flex-start',
                                content: '""',
                                background: `url(${menuItemUrl === item.url ? item.iconOn : item.icon}) no-repeat center center`,
                                width: '100%',
                                height: '100%'
                              },
                              cursor: 'pointer',
                              '&:hover': {
                                background: 'rgba(166, 255, 112, 0.05)'
                              }
                            }}
                            onClick={() => {
                              handleClickItem(item, menu.title);
                            }}
                          ></Box>
                        </Tooltip>
                      ))}
                  </Box>
                );
            })}
          </Box>
        )}

        {!collapsed && <Footer />}
      </Box>
    </>
  );
};

export default Sidebar;
