import { Select, MenuItem, Button, Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

import './scss/buyCrypto.scss';

import { Vector, CHAINBITS, MoonPay, paybis, Go_back } from 'static/images';

export default function BuyCrypto(prop) {
  const { goBack } = prop;

  const handleGoBack = () => {
    goBack && goBack();
  };

  const thirdDepositList = [
    { name: 'CHAINBITS', img: CHAINBITS, url: 'https://buy.chainbits.com/' },
    { name: 'MoonPay', img: MoonPay, url: 'https://buy.moonpay.com/' },
    { name: 'paybis', img: paybis, url: 'https://paybis.com/' }
  ];
  const goThirdUrl = (item) => {
    window.open(item.url, '_blank');
    gtag('event', 'click_third_deposit', {
      type: item.name,
      isInvite: window.localStorage.getItem('invit_code') ? `ad-${item.name}` : `no-${item.name}`
    });
    if (window.localStorage.getItem('invit_code') === 'bitcoinist') {
      gtag('event', 'bitcoinist_click_third_deposit', {
        type: item.name
      });
    }
  };

  return (
    <Box p={1}>
      <div className="buy-title">
        <img src={Go_back} alt="goBack" className="go-back" onClick={handleGoBack} />
        Buy Crypto cards{' '}
      </div>
      <Box className="buy-desc">
        While we look for a reliable card payment processor, you can purchase crypto using your card via the recommended third-party
        services below. After Purchasing the crypto,you can{' '}
        <span style={{ textDecoration: 'underline', cursor: 'pointer', color: '#B6C9F2' }} onClick={handleGoBack}>
          deposit it drrectly to your 21BITS account.
        </span>
      </Box>

      <Box className="buy-tips">
        <img src={Vector} alt="Vector" />
        <div>Please ensure tokens such as BNB or USDT are BEP-20 and only sent over the BNB Chain [BNB] network.</div>
      </Box>

      <Box className="jump-img">
        {thirdDepositList.map((item) => (
          <div key={item.name} onClick={() => goThirdUrl(item)}>
            <img src={item.img} alt={item.name} />
          </div>
        ))}
        {/* <a href="https://buy.chainbits.com/ " target="_blank">
          <img src={CHAINBITS} alt="CHAINBITS" />
        </a>
        <a href="https://buy.moonpay.com/" target="_blank">
          <img src={MoonPay} alt="MoonPay" />
        </a>
        <a href="https://paybis.com/" target="_blank">
          <img src={paybis} alt="paybis" />
        </a> */}
      </Box>
    </Box>
  );
}
